<template>
    <div>
        <div class="header-row">
           <div class="heade-div">
               <div class="logo" @click="$router.push('/')">
                   <img style="height: 60px;margin-right: 12px" :src="url_prefix + appInfo.pc_logo" v-if="appInfo.pc_logo">
<!--                   <p class="title">{{appInfo.app_name}}</p>-->
               </div>
               <div class="disCenter height100">
                     <div class="homePage"  style="margin-right: 50px;cursor: pointer;" @click="$router.push('/')">
                         <img src="../assets/homeIcon.png" style="width: 22px;height: 21px;margin-right: 10px" alt="">
                        主页
                    </div>
<!--                   <div class="down" :class="$route.path == '/merchant' ? 'homePage1' : ''" style="cursor:pointer;" @click="$router.push('/merchant')">-->
<!--                        APP下载-->
<!--                   </div>-->
               </div>
           </div>
        </div>
        <div class="leftFixed">
            <ul>
                <li>
                    <div class="row qqRow">
                        <img src="../assets/qq.png" alt="">
                        <div class="content-row qqContent">
                            <div class="content qqHover" @click="openPage">
                                <span >
                                   {{appInfo.qq_contact}}
                                </span>
                            </div>
                        </div>
                    </div>

                </li>
                <li>
                    <div class="row ifRow">
                        <img src="../assets/ifont.png" alt="">
                        <div class="content-row ifContent">
                            <div class="content">
                                <span>
                                    {{appInfo.tel_contact}}
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row erRow">
                        <img src="../assets/erweima.png" alt="">
                        <div class="content-row erContent" >
                            <div class="content" style="display: flex;justify-content: center;align-items: center;padding: 20px 0">
                                <img style="width: 100px;height: 100px;" :src="preFix + appInfo.kf_contact" alt="">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <router-view></router-view>
        <footer class="footer" v-if="$route.path != '/'">
            <span v-html="appInfo.copyright"></span>
        </footer>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "index",
        data(){
            return{
                contactList:{},
                preFix:null,
                appInfo:{},
                url_prefix:'',
            }
        },
        created() {
            this.preFix = api.url_prefix
            console.log(this.$route.path)
            this.getAppInfoFn()
            this.url_prefix = api.url_prefix
        },
        methods:{
            getAppInfoFn(){
              this.$get({
                  url:api.app_info
              }).then((res)=>{
                  this.appInfo = res.data.app_info
              })
            },
            // contactFn(){
            //     this.$get({
            //         url:api.contact
            //     }).then((res)=>{
            //         this.contactList = res.data.data
            //     })
            // },
            openPage(){
                window.open('http://wpa.qq.com/msgrd?v=3&uin='+this.contactList.qq +'&site=在线客服&menu=yes')
            }
        }
    }
</script>

<style scoped lang="scss">
    .logo{
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
            height: 40px!important;
        }
    }
    .title{
        font-size: 25px;
        color: white;
        font-weight: bold;
        text-shadow: 0px 1px 8px rgba(0, 66, 176, 0.43);
        font-family: Microsoft YaHei;
    }
    .homePage{
        position: relative;
        color: white;
        height: 100%;
        font-size: 20px;
        display: flex;
        align-items: center;
        line-height: 20px;
    }
    .homePage1::after{
        content: '';
        width: 60px;
        height: 8px;
        background: #FFFFFF;
        border-radius: 5px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
    }
    .down{
        position: relative;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .down1::after{
        content: '';
        width: 100px;
        height: 8px;
        background: #FFFFFF;
        border-radius: 5px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
    }
    /*.homePage:hover{*/
    /*    color: #888888;*/
    /*}*/
    /*.down:hover{*/
    /*    color: #888888;*/
    /*}*/
    .header-row{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*padding: 0 120px;*/
        box-sizing: border-box;
        background-color: transparent;
        z-index: 100;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        .heade-div{
            width: 1200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .leftFixed{
        position: fixed;
        right: 200px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
    }
    li{
        list-style: none;
    }
    .row:hover{
        background-color: #0076DD;
    }
    .row{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 51px;
        height: 51px;
        transition: 0.5s;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.3);
        margin-bottom: 28px;
        img{
            width: 22px;
            height: 22px;
        }
    }
    .qqRow:hover .qqContent{
        display: block;
    }
    .ifRow:hover .ifContent{
        display: block;
    }
    .erRow:hover .erContent{
        display: block;
    }
    .content-row{
        display: none;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        padding: 20px;
        box-sizing: border-box;
        .content{
            display: flex;
            width: 150px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #fff;
            position: absolute;
            text-align: center;
            justify-content: center;
            left: -150px;
            top: 50%;
            border-radius: 32px;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
    .trigon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid white;
        border-bottom: 5px solid transparent;
    }

    .footer{
        background-color: #282828;
        z-index:100;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 12px;
    }
</style>
